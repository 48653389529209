<template>
  <!-- Testimonial Start -->
  <div class="container-xxl">
    <div class="container testimonial-section py-5">
      <h2 class="text-center">What a customer</h2>
      <vueper-slides
        class="no-shadow"
        :visible-slides="3"
        :gap="3"
        :slide-ratio="1 / 4"
        :dragging-distance="200"
        :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
      >
        <vueper-slide v-for="(comment, i) in slidesAll" :key="i">
          <template #content>
            <div
              class="vueperslide__content-wrapper"
              style="flex-direction: column"
            >
              <div>
                <!-- <i class="material-icons fa fa-user"></i> -->
                <img :src="comment.profile" alt="" />
                <h6>{{ $t(comment.name) }}</h6>
                <p>{{ $t(comment.comment) }}</p>
              </div>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
  <!-- Testimonial End -->
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: {
    VueperSlides,
    VueperSlide,
  },
  data: () => ({
    slidesAll: [
      {
        name: "Serkan",
        profile: require("../assets/glory life/customer.png"),
        comment: "SerkanDesc",
      },
      {
        name: "Esra",
        profile: require("../assets/glory life/customer.png"),
        comment: "EsraDesc",
      },
      {
        name: "Deniz",
        profile: require("../assets/glory life/customer.png"),
        comment: "DenizDesc",
      },
      {
        name: "Baris",
        profile: require("../assets/glory life/customer.png"),
        comment: "BarisDesc",
      },
    ],
  }),
};
</script>

<style>
</style>