
<template>
  <header>
    <!-- top navbar start-->
    <div class="navbar container-fluid top-navbar">
      <div class="row">
        <div class="col nav-item dropdown">
          <div class="nav-link dropdown-toggle" title="Change Language">
            <img alt="Language" src="../assets/world.svg" />
          </div>
          <div class="dropdown-menu bg-light m-0">
            <span
              class="dropdown-item"
              title="türkçe"
              @click="changeLanguage('tr')"
              >Türkçe</span
            >
            <span
              class="dropdown-item"
              title="english"
              @click="changeLanguage('en')"
              >English</span
            >
          </div>
        </div>
        <div class="col">
          <a title="Call Us" href="tel:+905416265399"
            ><i class="fa fa-phone"></i
          ></a>
        </div>
      </div>
    </div>
    <!-- top navbar end -->

    <!-- Navbar Start -->
    <nav
      v-if="show_web_header == true"
      class="navbar navbar-expand-lg py-0 px-5"
    >
      <router-link
        to="/"
        class="navbar-brand d-flex align-items-center px-0 border-bottom"
        title="Glory Life"
      >
        <img
          class="logo"
          src="../assets/glory life/glory-life-logo.png"
          alt="glory life logo"
        />
      </router-link>
      <div class="border-bottom" id="navbarCollapse">
        <div class="navbar-nav ms-auto p-4 p-lg-0">
          <router-link
            to="/"
            class="nav-item nav-link active"
            :title="$t('Home')"
            >{{ $t("Home") }}</router-link
          >
          <span
            @click="scrollToSection('rooms')"
            class="nav-item nav-link"
            :title="$t('Rooms')"
            >{{ $t("Rooms") }}</span
          >
          <span
            @click="scrollToSection('our-services')"
            class="nav-item nav-link"
            :title="$t('Services')"
            >{{ $t("Services") }}</span
          >
          <!-- <router-link
            to="/our-services"
            class="nav-item nav-link"
            :title="$t('Services')"
            >{{ $t("Services") }}</router-link
          > -->
          <span
            @click="scrollToSection('our-services')"
            class="nav-item nav-link"
            :title="$t('AboutUs')"
            >{{ $t("AboutUs") }}</span
          >
          <span
            @click="scrollToSection('contact-us')"
            class="nav-item nav-link"
            :title="$t('ContactUs')"
            >{{ $t("ContactUs") }}</span
          >
        </div>
      </div>
    </nav>

    <!-- mobile navbar section -->
    <nav v-if="show_mobile_header == true" id="mobile-navbar">
      <div class="container-fluid h-100">
        <div
          class="row h-100 text-center d-flex justify-content-center align-items-center"
        >
          <div class="col d-flex justify-content-center align-items-center">
            <div>
              <ul>
                <li>
                  <b-dropdown :text="this.$i18n.locale" left>
                    <b-dropdown-item
                      ><span
                        class="dropdown-item"
                        title="Türkçe"
                        @click="changeLanguage('tr')"
                        >Türkçe</span
                      ></b-dropdown-item
                    >
                    <b-dropdown-item
                      ><span
                        class="dropdown-item"
                        title="English"
                        @click="changeLanguage('en')"
                        >English</span
                      ></b-dropdown-item
                    >
                  </b-dropdown>
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <router-link to="/" :title="$t('Home')">
              <img
                class="mobile-logo"
                src="../assets/glory life/glory-life-logo.png"
                alt="logo"
              />
            </router-link>
          </div>
          <div class="col">
            <div>
              <span class="mobile-toggler" @click="TOGGLE()">⇆</span>
              <div class="col mobile-nav-items">
                <ul>
                  <li @click="TOGGLE()">
                    <span
                      @click="scrollToSection('rooms')"
                      class="nav-item nav-link"
                      :title="$t('Rooms')"
                      >{{ $t("Rooms") }}</span
                    >
                  </li>
                  <li @click="TOGGLE()">
                    <span
                      @click="scrollToSection('our-services')"
                      class="nav-item nav-link"
                      :title="$t('Services')"
                      >{{ $t("Services") }}</span
                    >
                  </li>
                  <!-- <li @click="TOGGLE()">
                    <router-link
                      to="/our-services"
                      :title="$t('Services')"
                      class="nav-item nav-link"
                      >{{ $t("Services") }}</router-link
                    >
                  </li> -->

                  <li @click="TOGGLE()">
                    <span
                      @click="scrollToSection('our-services')"
                      class="nav-item nav-link"
                      :title="$t('AboutUs')"
                      >{{ $t("AboutUs") }}</span
                    >
                  </li>
                  <li @click="TOGGLE()">
                    <span
                      @click="scrollToSection('contact-us')"
                      class="nav-item nav-link"
                      :title="$t('ContactUs')"
                      >{{ $t("ContactUs") }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Navbar End -->
  </header>
</template>
<script>
export default {
  data() {
    return {
      show_web_header: false,
      show_mobile_header: false,
    };
  },
  created() {
    // eğer ekran genişliği değiştirilirse bu event sayesinde fonksiyonu yeniden tetikliyoruz
    window.addEventListener("resize", () => {
      this.FindScreenSize();
    });
    //ilk açılışta her zaman bu kontrol gerçekleşecek
    this.FindScreenSize();
  },
  methods: {
    FindScreenSize() {
      this.show_mobile_header = window.innerWidth < 996; // window.innerWidth < 996 bu kod boolean döndürür.
      this.show_web_header = !this.show_mobile_header; // show_web_header değişkeni sürekli diğerine bağlı olarak zıt şekilde güncellenir.
    },
    changeLanguage(lang) {
      if (lang == "tr") {
        this.$i18n.locale = "tr";
        // Yeni dil değerini yerel depolamada saklayın
        localStorage.setItem("lang", this.$i18n.locale);
        return;
      }
      // Dil değiştirme işlemlerini burada yapabilirsiniz
      // Örneğin, aktif dil Türkçe ise İngilizce'ye, İngilizce ise Türkçe'ye geçebilirsiniz
      this.$i18n.locale = this.$i18n.locale = "en";
      // Yeni dil değerini yerel depolamada saklayın
      localStorage.setItem("lang", this.$i18n.locale);
    },
    scrollToSection(sectionId) {
      let hostName = window.location.pathname;
      if (hostName == "/our-services") {
        window.location = `/#${sectionId}`;
      } else {
        // İlgili bölümü bulmak için ID'ye göre seçim yapalım
        const section = document.getElementById(sectionId);

        if (section) {
          // Bölümü ekranın üstüne getirelim
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    TOGGLE() {
      // mobile toggle controller
      let toggle = document.querySelector(".mobile-nav-items");
      if (toggle.classList.contains("show")) {
        toggle.style.display = "none";
        toggle.classList.remove("show");
      } else {
        toggle.style.display = "block";
        toggle.classList.add("show");
      }
    },
  },
};
</script>