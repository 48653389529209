<template>
  <section class="services-page mb-5">
    <div class="main-top">
      <div class="container-fluid">
        <div class="row">
          <div class="col-11">
            <a
              href="https://antalyasuites.com/"
              target="_blank"
              :title="$t('OneClickRes')"
              >{{ $t("OneClickRes") }} <i class="fa fa-hand"></i
            ></a>
          </div>
          <div class="col-1 logo">
            <img
              src="@/assets/glory life/suitesLogo.png"
              alt="Antalya Suites"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- our sevices top section start -->
    <div class="container services-top-section">
      <div class="row top">
        <div class="col-lg-5">
          <figure>
            <img
              src="../assets/glory life/glory-life-cafe.png"
              class="img-thumbnail"
              alt="glory-life-cafe"
            />
          </figure>
        </div>
        <div class="col-lg-7">
          <div>
            <div>
              <h3>{{ $t("GloryLifeCafe") }}</h3>
              <!-- <hr class="w-50" /> -->
              <p>{{ $t("CafeDesc1") }}</p>
              <p>{{ $t("CafeDesc2") }}</p>
              <p>{{ $t("CafeDesc3") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row bottom">
        <div class="col-lg-7">
          <div>
            <p>
              <strong> {{ $t("CleaningAndHygiene") }}</strong>
              <br />
              {{ $t("CleaningAndHygieneDesc") }}
            </p>
            <p>
              <strong> {{ $t("WithWarmAtmosphere") }}</strong> <br />
              {{ $t("WithWarmAtmosphereDesc") }}
            </p>
            <p>
              <strong>{{ $t("QualityProduct") }}</strong> <br />
              {{ $t("QualityProductDesc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <figure>
            <img
              class="img-thumbnail"
              src="../assets/glory life/glory-life-cafe-2.png"
              alt="services-img"
            />
          </figure>
        </div>
        <hr class="mt-4" />
      </div>
      <div class="container">
        <div class="my-5">
          <h2>{{ $t("TheWayGoodAtmosphere") }}</h2>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="card-item">
              <figure>
                <img
                  class=""
                  src="../assets/glory life/glory-life-cafe-3.png"
                  alt="Glory Life Cafe"
                />
              </figure>
              <h3>{{ $t("EducatedAndProfessional") }}</h3>
              <p>
                {{ $t("EducatedAndProfessionalDesc") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card-item">
              <figure>
                <img
                  class=""
                  src="../assets/glory life/glory-life-cafe-4.png"
                  alt="Glory Life Cafe"
                />
              </figure>
              <h3>{{ $t("OurFoodAndDrinks") }}</h3>
              <p>
                {{ $t("OurFoodAndDrinksDesc") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card-item">
              <figure>
                <img
                  class=""
                  src="../assets/glory life/glory-life-cafe-5.png"
                  alt="Glory Life Cafe"
                />
              </figure>
              <h3>{{ $t("InvitingAreas") }}</h3>
              <p>
                {{ $t("InvitingAreasDesc") }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="my-5"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <h2>{{ $t("GetTheBestServices") }}</h2>
          <hr class="w-50" />
          <p>
            {{ $t("GetTheBestServicesDesc") }}
          </p>
        </div>
      </div>

      <!-- animations with services icons -->
      <div class="container-fluid mt-5 d-none d-lg-block">
        <div class="services-box-section">
          <div class="col-lg-2 service-box">
            <img src="../assets/services/wifi.jpg" alt="wifi" />
            <figure class="hover-icon">
              <img src="../assets/icons/wifi.png" alt="wifi" />
            </figure>
          </div>
          <div class="col-lg-2 service-box">
            <img src="../assets/services/coffee.jpg" alt="coffee" />
            <figure class="hover-icon">
              <img src="../assets/icons/coffee.png" alt="coffee" />
            </figure>
          </div>
          <div class="col-lg-2 service-box">
            <img src="../assets/services/pool.jpg" alt="pool" />
            <figure class="hover-icon">
              <img src="../assets/icons/pool.png" alt="pool" />
            </figure>
          </div>
          <div class="col-lg-2 service-box">
            <img src="../assets/services/sauna.jpg" alt="sauna" />
            <figure class="hover-icon">
              <img src="../assets/icons/sauna.png" alt="sauna" />
            </figure>
          </div>
          <div class="col-lg-2 service-box">
            <img src="../assets/services/child-park.jpg" alt="child-park" />
            <figure class="hover-icon">
              <img src="../assets/icons/child-park.png" alt="child-park" />
            </figure>
          </div>
          <div class="col-lg-2 service-box">
            <img src="../assets/services/parking.jpg" alt="parking" />
            <figure class="hover-icon">
              <img src="../assets/icons/parking.png" alt="parking" />
            </figure>
          </div>
          <div class="col-lg-2 service-box">
            <img src="../assets/services/beauty.jpg" alt="beauty" />
            <figure class="hover-icon">
              <img src="../assets/icons/beauty.png" alt="beauty" />
            </figure>
          </div>
          <div class="col-lg-2 service-box">
            <img src="../assets/services/gym.jpeg" alt="gym" />
            <figure class="hover-icon">
              <img src="../assets/icons/gym.png" alt="gym" />
            </figure>
          </div>
          <div class="col-lg-2 service-box">
            <img src="../assets/services/extra.jpg" alt="extra" />
            <figure class="hover-icon">
              <img src="../assets/icons/extra.png" alt="extra" />
            </figure>
          </div>
          <div class="col-lg-2 service-box">
            <img src="../assets/services/security.jpg" alt="security" />
            <figure class="hover-icon">
              <img src="../assets/icons/security.png" alt="security" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- our sevices top section end -->
</template>

<script>
export default {};
</script>

<style>
</style>