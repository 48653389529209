<template>
  <div>
    <div class="row">
      <div class="col-lg-2">
        <figure>
          <img
            src="@/assets/glory life/suitesLogo.png"
            alt="Antalya Suites
          Logo"
          />
        </figure>
      </div>
      <div class="col-lg-10 reservation-wrapper">
        <div>
          <h3>{{ $t("AntalyaSuitesForRes") }}</h3>
          <p>{{ $t("ExploreOtherDestinations") }}</p>
          <a
            href="https://antalyasuites.com/"
            target="_blank"
            :title="$t('MoreInformation')"
            >{{ $t("MoreInformation") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>