import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// initialize route pages
import Home from '../Views/Home-Page.vue'
import Services from '../Views/Our-Services.vue'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/our-services',
        name: 'Our Services',
        component: Services
    }
]
const router = new VueRouter({
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    //  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`;
    document.title = `${process.env.VUE_APP_TITLE}`;
    next();
});

export default router