<template>
  <main class="main">
    <!-- main section -->
    <section class="main-section">
      <div class="main-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-11">
              <a
                href="https://antalyasuites.com/"
                target="_blank"
                :title="$t('OneClickRes')"
                >{{ $t("OneClickRes") }} <i class="fa fa-hand"></i
              ></a>
            </div>
            <div class="col-1 logo">
              <img
                src="@/assets/glory life/suitesLogo.png"
                alt="Antalya Suites Logo"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="main-content container-fluid">
        <div class="row">
          <div class="col-lg-4 main-left">
            <h2>
              {{ $t("MakeYourself") }}
            </h2>
            <div class="mt-1">
              <a
                href="https://antalyasuites.com/"
                target="_blank"
                :title="$t('FindARoom')"
                >{{ $t("FindARoom") }}</a
              >
            </div>
            <p>{{ $t("SimpleUnique") }}</p>
          </div>
          <div class="col-lg-8 main-right">
            <figure>
              <img
                src="../assets/glory life/glory-life-main.jpg"
                alt="Glory Life"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <!-- end main section -->

    <!-- reservation section wrapper -->
    <section class="container-fluid reservation-section">
      <reservationWrapper />
    </section>
    <!-- end reservation section wrapper -->

    <!-- rooms tab start -->
    <section id="rooms" class="tab-side container-fluid">
      <div class="container my-5">
        <h2>{{ $t("Rooms") }}</h2>
        <p>
          {{ $t("RoomsDesc") }}
        </p>
      </div>
      <div class="container-fluid tabs-bg">
        <div class="container">
          <b-tabs content-class="mt-5">
            <!-- 1+1 tab -->
            <b-tab :title="$t('All')" active>
              <!-- slider -->
              <VueperSlides
                arrows-outside
                :bullets="false"
                :slideMultiple="true"
                :gap="5"
                :lazy="true"
                autoplay
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="3"
                :dragging-distance="70"
                :breakpoints="{
                  800: {
                    visibleSlides: 3,
                    slideMultiple: 3,
                  },
                }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in slidesAll"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </b-tab>

            <!-- 2+1 tab -->
            <b-tab :title="$t('1+1Apartment')">
              <!-- slider -->
              <VueperSlides
                :bullets="false"
                :gap="5"
                :lazy="true"
                :slideMultiple="true"
                autoplay
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="3"
                :dragging-distance="70"
                :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in slides1_1"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </b-tab>

            <!-- 3+1 tab -->
            <b-tab :title="$t('3+1Apartment')">
              <!-- slider -->
              <VueperSlides
                :bullets="false"
                autoplay
                :gap="5"
                :lazy="true"
                :slideMultiple="true"
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="3"
                :dragging-distance="70"
                :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in slides3_1"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </b-tab>

            <!-- 3+1 tab -->
            <b-tab :title="$t('Outside')">
              <!-- slider -->
              <VueperSlides
                :bullets="false"
                autoplay
                :gap="5"
                :lazy="true"
                :slideMultiple="true"
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="3"
                :dragging-distance="70"
                :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in slidesOutside"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>
    <!-- rooms tabs end -->

    <!-- our services start -->
    <section id="our-services" class="container services-section mt-5">
      <div class="container-fluid my-5">
        <h2>{{ $t("Services") }}</h2>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="card-item">
            <figure>
              <img
                src="../assets/glory life/glory-life-cafe-smile.jpg"
                :alt="$t('GloryLifeCafe')"
              />
            </figure>
            <h3>{{ $t("GloryLifeCafe") }}</h3>
            <p>
              {{ $t("QualityProductDesc") }}
            </p>
            <router-link
              tag="a"
              to="/our-services"
              :title="$t('GloryLifeCafe')"
            >
              <i
                class="w-100 text-center fa fa-arrow-right"
                style="font-size: 1.6em"
              ></i>
            </router-link>
          </div>
        </div>
        <!-- <div class="col-lg-3">
          <div class="card-item">
            <figure>
              <img
                src="../assets/glory life/bagli-img.png"
                :alt="$t('Connected')"
              />
            </figure>
            <h3>{{ $t("Connected") }}</h3>
            <p>
              {{ $t("ConnectedDesc") }}
            </p>
          </div>
        </div> -->
        <div class="col-lg-4">
          <div class="card-item">
            <figure>
              <img
                src="../assets/glory life/modern-img.png"
                :alt="$t('Modern')"
              />
            </figure>
            <h3>{{ $t("Modern") }}</h3>
            <p>
              {{ $t("ModernDesc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-item">
            <figure>
              <img
                src="../assets/glory life/pozitif-img.png"
                :alt="$t('Positive')"
              />
            </figure>
            <h3>{{ $t("Positive") }}</h3>
            <p>
              {{ $t("PositiveDesc") }}
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="text-center my-4">
        <router-link to="/our-services" title="Glory Life Cafe" tag="a">
          {{ $t("ForInformationAboutGloryCafe") }}
          <strong>{{ $t("ClickHere") }}</strong>
        </router-link>
      </div> -->
      <div class="container icons mt-5">
        <!-- <hr class="w-75" /> -->
        <figure>
          <img src="../assets/glory life/ikonlar.png" :alt="$t('ClickHere')" />
        </figure>
      </div>
    </section>
    <!-- our services end -->

    <!-- Testimonial section start -->
    <testimonialComp />
    <!-- Testimonial section end -->

    <!-- Contact us section start -->
    <ContactComp />
    <!-- Contact us section end -->
  </main>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import reservationWrapper from "@/components/Reservation-Wrapper.vue";
import testimonialComp from "@/components/Testimonial-Comp.vue";
import ContactComp from "@/components/Contact-Comp.vue";
export default {
  components: {
    VueperSlides,
    VueperSlide,
    reservationWrapper,
    testimonialComp,
    ContactComp,
  },
  data() {
    return {
      roomsVisibleSlides: null,
      testimonialsVisibleSlides: 3,
      slidesAll: [
        {
          image: require("@/assets/3+1/glory-life-3+1-7.jpg"),
        },

        {
          image: require("@/assets/1+1/glory-life-1+1-1.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-2.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-3.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-4.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-8.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-6.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-9.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-5.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-6.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-7.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-8.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-9.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-10.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-1.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-2.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-3.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-4.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-5.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-10.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-1.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-2.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-3.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-4.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-5.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-6.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-7.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-8.jpg"),
        },
      ],
      slides1_1: [
        {
          // title: "Slide #1",
          // content: "Slide 1 content.",
          image: require("@/assets/1+1/glory-life-1+1-1.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-2.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-3.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-4.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-5.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-6.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-7.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-8.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-9.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-10.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-11.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-12.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-13.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-14.jpg"),
        },
        {
          image: require("@/assets/1+1/glory-life-1+1-15.jpg"),
        },
      ],
      slides3_1: [
        {
          image: require("@/assets/3+1/glory-life-3+1-1.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-2.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-3.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-4.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-5.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-6.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-7.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-8.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-9.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-10.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-11.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-12.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-13.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-14.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-15.jpg"),
        },
        {
          image: require("@/assets/3+1/glory-life-3+1-16.jpg"),
        },
      ],
      slidesOutside: [
        {
          image: require("@/assets/outside/glory-life-outside-1.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-2.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-3.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-4.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-5.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-6.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-7.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-8.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-9.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-10.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-11.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-12.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-13.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-14.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-16.jpg"),
        },
        {
          image: require("@/assets/outside/glory-life-outside-17.jpg"),
        },
      ],
    };
  },
  created() {
    if (window.innerWidth < 997) {
      this.roomsVisibleSlides = 1;
    } else {
      this.roomsVisibleSlides = 3;
    }
  },
};
</script>